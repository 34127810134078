import { Application } from "@hotwired/stimulus";
import "@hotwired/turbo-rails";
import { registerControllers } from "../registerControllers";
import "./App";

// stimulus
const application = Application.start();
application.warnings = false;
application.debug = false;

registerControllers(
  application,
  import.meta.glob("../../../app/components/hairbook/**/*_controller.{js,ts}", {
    eager: true,
  }),
);
registerControllers(
  application,
  import.meta.glob("../../../app/components/ui/**/*_controller.{js,ts}"),
);

import VideoController from "../controllers/video_controller";
application.register("video", VideoController);

import TrackingController from "../controllers/tracking_controller";
application.register("tracking", TrackingController);

import VisibilityController from "../controllers/visibility_controller";
application.register("visibility", VisibilityController);

window.onload = function () {
  if (/iP(hone|ad)/.test(window.navigator.userAgent)) {
    document.body.addEventListener(
      "touchstart",
      function () {
        return true;
      },
      false,
    );
  }
};
